*, :before, :after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: Proxima Nova;
  font-weight: 400;
  text-decoration: none;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.widget-application * {
  scrollbar-width: none;
}

.widget-section, .widget-section .widget {
  width: 100%;
  height: 100%;
}

.widget-section .widget-logo {
  top: 7px !important;
  left: 32px !important;
}

@media screen and (width <= 670px) {
  .widget-section .widget-logo {
    top: 1px !important;
    left: 21px !important;
  }
}

.widget-section .widget-logo img {
  width: 89px !important;
  height: 89px !important;
}

@media screen and (width <= 670px) {
  .widget-section .widget-logo img {
    width: 62px !important;
    height: 62px !important;
  }
}

.widget-section .widget-logo-second {
  z-index: 10;
  cursor: pointer;
  position: absolute;
  top: 37px;
  right: 32px;
}

@media screen and (width <= 670px) {
  .widget-section .widget-logo-second {
    width: 64px;
    top: 22px;
    right: 21px;
  }
}

.widget-section .widget-enter-fullscreen, .widget-section .widget-watermark-button {
  display: none;
}

.widget-section .widget-tab--area {
  padding: 5px 30px 60px !important;
}

@media screen and (width <= 768px) {
  .widget-section .widget-tab__isometry--plan {
    max-width: calc(100% - 120px) !important;
    left: 45% !important;
  }
}

.widget-section .widget-tab__isometry-shader {
  margin: 0 auto 90px !important;
}

.widget-section .widget-tab__panorama-close-map {
  top: 60px !important;
}

@media screen and (width <= 670px) {
  .widget-section .widget-tab__panorama-close-map {
    top: 40px !important;
    right: 15px !important;
  }
}

@media screen and (width <= 450px) {
  .widget-section .widget-tab__panorama-map--bottom {
    bottom: 25px !important;
  }

  .widget-section .widget-tab__panorama-map--scaled {
    transform: scale(1)translate(-50%, -93%) !important;
  }

  .widget-section .widget-tab-buttons-vertical {
    flex-direction: row !important;
    bottom: 25px !important;
  }

  .widget-section .widget-tab__panorama-controls-container {
    transform: unset !important;
    bottom: 80px !important;
    right: 21px !important;
    left: unset !important;
  }
}

.widget-section .widget-tab__panorama-close-map {
  display: none !important;
}

@media screen and (width <= 450px) {
  .widget-section .widget-tab-rooms {
    height: unset !important;
  }

  .widget-section .widget-tab__isometry-arrows {
    bottom: 165px !important;
  }

  .widget-section .widget-instructions {
    transform: translateY(-5%);
  }
}

@media screen and (height <= 640px) {
  .widget-section .widget-instructions {
    transform: translateY(-15%);
  }
}
/*# sourceMappingURL=index.bf01956d.css.map */
