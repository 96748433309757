*, *:before, *:after
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-family: 'Proxima Nova'
  text-decoration: none
  font-weight: 400
  color: #fff
  outline: none
  padding: 0
  margin: 0
  box-sizing: border-box
  -webkit-box-sizing: border-box

body
  width: 100%
  height: 100%
  overflow: hidden

.widget-application *
  scrollbar-width: none

.widget-section
  width: 100%
  height: 100%
  .widget
    width: 100%
    height: 100%
    &-logo
      left: 32px !important
      top: 7px !important
      @media screen and (max-width: 670px)
        left: 21px !important
        top: 1px !important
      img
        width: 89px !important
        height: 89px !important
        @media screen and (max-width: 670px)
          width: 62px !important
          height: 62px !important
      &-second
        position: absolute
        top: 37px
        right: 32px
        z-index: 10
        cursor: pointer
        @media screen and (max-width: 670px)
          width: 64px
          top: 22px
          right: 21px
    &-enter-fullscreen
      display: none
    &-watermark-button
      display: none
    &-tab
      &--area
        padding: 5px 30px 60px 30px !important
      &__isometry--plan
        @media screen and (max-width: 768px)
          left: 45% !important
          max-width: calc( 100% - 120px ) !important
      &__isometry-shader
        margin: 0 auto 90px auto !important
      &__panorama-close-map
        top: 60px !important
        @media screen and (max-width: 670px)
          top: 40px !important
          right: 15px !important
      &__panorama-map--bottom
        @media screen and (max-width: 450px)
          bottom: 25px !important
      &__panorama-map--scaled
        @media screen and (max-width: 450px)
          transform: scale(1) translate(-50%, -93%) !important
      &-buttons-vertical
        @media screen and (max-width: 450px)
          bottom: 25px !important
          flex-direction: row !important
      &__panorama-controls-container
        @media screen and (max-width: 450px)
          bottom: 80px !important
          right: 21px !important
          transform: unset !important
          left: unset !important
      &__panorama-close-map
        display: none !important
      &-rooms
        @media screen and (max-width: 450px)
          height: unset !important
      &__isometry-arrows
        // bottom: 100px !important
        @media screen and (max-width: 450px)
          bottom: 165px !important
    &-instructions
      @media screen and (max-width: 450px)
        transform: translateY(-5%)
      @media screen and (max-height: 640px)
        transform: translateY(-15%)
